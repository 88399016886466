/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-direct-mutation-state */
// ^^^ there are some funky lifecycle behaviours in this component, mostly designed
//     to squeeze super performance out of the thing by mutating state or class properties
//     directly in anticipation of forthcoming render cycles, e.g. during drag, when 
//     we know we'll be re-rendering *often*

import React from 'react'
import ReactDOM from 'react-dom'
import './react-large-tree.scss'
import autobind from 'autobind-decorator'
import { filterTree, pathToChild } from './tree-utils'
import throttle from 'lodash.throttle'
import { ContextMenuTrigger } from "./context-menu"


/*

  Heads Up! This component is pretty weird. 
  
  If you're new to React, please don't treat this component as a good general example. 
  There  are some advanced techniques at play here, which I would not recommend outside 
  of special use cases, such as drag and drop.

  Design goals:
  
  - keep the DOM as flat & simple as possible
  - Delegate events to the root node. all of em
  - use class properties to track state through drag events, and fire forceUpdate imperatively when relevant

*/

class ReactLargeTree extends React.Component {

  static displayName = "ReactLargeTree"

  constructor (props) {

    super(props)

    this.uniqueId = props.id || 'react-large-tree-' + Math.random() + '-' + Math.random() + '-' + Math.random()
    this.expandedForSearch = []
    this.dragAllowed       = true
    this.labelKey          = props.labelKey || 'label'
    this.currentDragChildKeys = []
    this.justDragged = []
    this.clickActionClass = ""
    this.multiSelection = {
      parent: null,
      selected: new Set(),
    }

    this.state = {
      expandedItems : [],
      toBeHidden    : [],
      dragging      : false
    }

    // we store an internal flat tree, so we don't have to do any tree recursion 
    // on state changes.
    this.flatTree = props.content 
      ? this.getFlatTree(props.content, [], props.uniqueKey) 
      : []

    // we store an internal copy of the branching tree, so we can latency compensate updates
    this.state.tree = props.content || {}

    this.setCanDragChildInto(props)
    this.setupWindowEvents()
    
    // a throttled update callback we can use during dragover phase
    // events fire very eagerly during dragover, and it's OK if we miss some updates.
    // during other phases we want ALL forceupdate calls to run.
    this.dragoverUpdate = throttle(this.forceUpdate, 30)
  }
  
  setupWindowEvents () {
    window.addEventListener("click", this.rootClearSelection)
    window.addEventListener("keydown", this.setClickActionClass)
    window.addEventListener("keyup", this.clearClickActionClass)
    window.addEventListener("blur", this.clearClickActionClass)
  }
  
  tearDownWindowEvents () {
    window.removeEventListener("click", this.rootClearSelection)
    window.removeEventListener("keydown", this.setClickActionClass)
    window.removeEventListener("keyup", this.clearClickActionClass)
    window.removeEventListener("blur", this.clearClickActionClass)
  }
    
  @autobind
  setClickActionClass (e) {
    if (!this.props.multiDrag) return // irrelevant for single-drag mode
    let newClass
    const key = e.key.toLowerCase()
    switch (key) {
      case "control":
      case "meta":
        newClass = "selection-toggle"
        break
      case "shift":
        newClass = "selection-range"
        break
      default:
        newClass = ""
    }
    if (newClass != this.clickActionClass) {
      this.clickActionClass = newClass
      this.forceUpdate()
    }
  }
  
  @autobind
  clearClickActionClass () {
    if (this.clickActionClass == "") return
    this.clickActionClass = ""
    this.forceUpdate()
  }

  /**
   * Set a callback at this.canDragChildInto
   * 
   * if this is not passed by props, the callback will always return true,
   * 
   * we take this approach of setting a callback, rather than checking for the 
   * presence of the prop every time we need to make a drag-into check to absolutely
   * minimise the amount of logic that need run during drag operations
   * @param {*} props 
   */
  setCanDragChildInto (props) {
    this.canDragChildInto = props.canDragChildInto ? props.canDragChildInto : () => true
  }

  /**
   * Specifically run some side effects when new props arrive.
   * 
   * - regenerate a flat representation of the tree if it has changed
   * - ensure that whatever child we're currently editing is expanded
   * - make sure the canDragChildInto callback is up-to-date
   * 
   * You may look at this and think it ought to use getDerivedStateFromProps. I
   * toyed with a state-deriving method for this, but it was getting rather 
   * complicated.
   * 
   * this was a pretty natural fit for the deprecated componentWillReceiveProps
   * lifecycle method, but it works pretty well as a post-update hook.
   * 
   * we burn a render cycle using this instead of componentWillReceiveProps, but
   * it's safer, and I think, easier to think about than either deriving state
   * or the UNSAFE_componentWillUpdate lifecycle method.
   * 
   * A future iteration might like to explore alternative memoisation strategies
   * for these three cases.
   * @param {*} prevProps 
   */
  componentDidUpdate (prevProps) {
    
    // if props have not changed, we do not need to run any of these side effects
    if (prevProps == this.props) return

    // rebuild the flat tree using the latest content
    if (this.props.content && this.props.content != prevProps.content) {
      this.flatTree = this.getFlatTree(this.props.content, this.state.expandedItems, this.props.uniqueKey)
      this.setState({ tree : this.props.content })
    }

    this.setCanDragChildInto(this.props)
  }

  componentWillUnmount () {
    // clear any outstanding timeouts
    if (this.justDraggedTimeout) clearTimeout(this.justDraggedTimeout)
    if (this.expandAnimationTimeout) clearTimeout(this.expandAnimationTimeout)
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    if (this.dragStartTimeout) clearTimeout(this.dragStartTimeout)
    this.tearDownWindowEvents()
  }

  getPathToChild (tree, childUnique) {
    const uniqueKey   = this.props.uniqueKey
    return pathToChild(uniqueKey, childUnique, tree);
  }
  
  /**
   * Ensure that a node is expanded
   * if the node is expanded for search, expand it fully, as if the user expanded
   * it manually
   */
  ensureExpanded (uniqueValue) {
    const userOpened = this.state.expandedItems.includes(uniqueValue)
    const openForSearch  = this.expandedForSearch.includes(uniqueValue)
    
    // if the node is expanded to show search results, we don't need to worry about
    // the animations that `toggleExpanded` provides, we just want to move the
    // node's uniqueVal from the set of expanded-for-search nodes into the set
    // of user-expanded nodes. 
    if (openForSearch) {
      this.state.expandedItems = this.state.expandedItems.concat([uniqueValue])
      this.expandedForSearch   = this.expandedForSearch.filter(item => item !== uniqueValue)
    // otherwise, if it's not user-opened, run the `toggle` routine to expand
    // the node
    } else if (!userOpened) {
      this.toggleExpanded(uniqueValue)
    }
  }

  /**
   * Toggle the expanded state of a node
   * if the node is expanded by the search algorithm, this will close it (removing
   * it from the set of items expanded to display search results)
   */
  toggleExpanded (uniqueValue) {

    const open = this.state.expandedItems.includes(uniqueValue) || this.expandedForSearch.includes(uniqueValue)

    if (open) {
      this.state.expandedItems = this.state.expandedItems.filter(item => item !== uniqueValue)
      this.expandedForSearch = this.expandedForSearch.filter(item => item !== uniqueValue)
    } else {
      /*
       during expand, we add a class to the expanded node, and node after it (and all its children)
       these classes are used to apply an animation to animate newly revealed children into the view

       the classes are subsequently removed to prevent re-running the animation on other DOM changes.
       */

      const uniqueVals = [...this.flatTree.keys()]
      const index = uniqueVals.indexOf(uniqueValue)
      const next = uniqueVals[index + 1] // get the uniqueValue for the next item in the tree
      this.slideInAfter = uniqueValue
      if (next) this.slideInBefore = next
      this.state.expandedItems = this.state.expandedItems.concat([uniqueValue])
    }

    this.updateFlatTree(true)
    this.forceUpdate()

    if (this.expandAnimationTimeout) clearTimeout(this.expandAnimationTimeout)
    this.expandAnimationTimeout = setTimeout(() => {
      this.slideInAfter = null
      this.slideInBefore = null
    }, 220)

  }
  
  /**
   * prevent drag and drop if `props.locked` is true, or we have a search term
   */
  isLocked () {
    const hasSearchTerm = Boolean(this.searchTerm && this.searchTerm.trim())
    const isPropsLocked = this.props.locked
    return isPropsLocked || hasSearchTerm
  } 

  doSearch (searchTerm, immediately = false) {

    if (!immediately) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchTerm = searchTerm
        this.updateFlatTree()
        this.forceUpdate()
      }, 330)
    } else {
      this.searchTerm = searchTerm
      this.updateFlatTree()
      this.forceUpdate()
    }

  }

  /**
   * Create a 2D Map of the visible part of the tree, keyed by each node's `node[props.uniqueKey]`
   */
  getFlatTree (tree, expandedItems, uniqueKey) {

    const toBeHidden = this.state.toBeHidden

    let   flatTree   = new Map()

    expandedItems = expandedItems.concat(this.expandedForSearch)
    
    // ensure that the editing child node is always expanded
    if (this.props.editingChild) {

      // get an array of items in the path to the editing child
      const pathToChild = this.getPathToChild(tree, this.props.editingChild)      
      expandedItems = expandedItems.concat(pathToChild)
    }

    // take a tree node and decide whether to push it to the flatTree
    // if it is expanded, send it's children to also be pushed to the tree
    const pushChildToFlatTree = (child, level = 0, willLeave = false) => {

      const node = Object.assign(child, {__level: level, __willLeave: willLeave})
      flatTree.set(node[uniqueKey], node)

      if (!child.children) { return }

      const expanded = (expandedItems.includes(child[uniqueKey]))
      
      node.__expanded = expanded

      if (
        level === 0 ||
        expanded && !this.currentDragChildKeys.includes(node[uniqueKey])
      ) {
        pushChildrenToFlatTree(child, level + 1, willLeave)
      }

    }

    // loop through a children array and push each to the flatTree
    const pushChildrenToFlatTree = (parent, level, willLeave = false) => {

      if (parent[uniqueKey] === toBeHidden) {
        willLeave = true
      }

      if (!parent.children || parent.children.length === 0) { return }

      const __parent = parent[uniqueKey]

      for (const child of parent.children) {

        child.__parent = __parent

        pushChildToFlatTree(child, level, willLeave)

      }

    }

    // kick it all off
    pushChildToFlatTree(tree)

    return flatTree

  }

  /**
   * Update the internal flat representation of the tree using the latest search term
   * and expanded nodes. 
   *
   * pass true to `ignoreFilters` to keep the current search-state.
   *
   */
  updateFlatTree (ignoreFilters = false) {
    const expandedForSearchOverride = ignoreFilters 
      ? this.expandedForSearch 
      : null
    const searchKey = this.labelKey || 'label'
    const uniqueKey = this.props.uniqueKey

    const [ tree, expanded ] = filterTree(
      this.state.tree, 
      this.searchTerm, 
      expandedForSearchOverride, 
      searchKey, 
      uniqueKey
    )
    
    this.expandedForSearch = expanded
    this.flatTree = this.getFlatTree(
      tree, 
      this.state.expandedItems, 
      this.props.uniqueKey
    )
  }

  // simulate a drag & drop move operation
  // (latency compensation for external actions, or work-in-progress for a save action)
  pruneAndReattach (childNodes, newParentId, position = 'into', target) {

    let successfulOps = 0
    let newChildIndex = 0
    
    const movedCount = childNodes.length

    let childNode = childNodes.pop()
    while (childNode) {

      successfulOps = 0
      newChildIndex = 0

      const uniqueKey   = this.props.uniqueKey
      const childUnique = childNode[uniqueKey]
      const checkAndPruneOrAdd = (node) => {
  
        if (!node.children && node[uniqueKey] === newParentId) {
          node.children = [childNode]
          return
        }
  
        if (!node.children) { return } // nothing more to do with this node
  
        if (node[uniqueKey] === newParentId) {
  
          // if we're just moving the node within the same child-set, remove the old instance
          if (childNode.__parent === newParentId) {
            const currentChildIndex = node.children.indexOf(childNode)
            node.children.splice(currentChildIndex, 1)
          }
  
          switch (position) {
            case 'before':
              newChildIndex = node.children.map(child => child[uniqueKey]).indexOf(target)
              break
            case 'after':
              newChildIndex = node.children.map(child => child[uniqueKey]).indexOf(target) + 1
              break
            default:
              newChildIndex = 0
          }
  
          // add the new instance
          // remove the private keys from the childNode
          Object.keys(childNode).filter(key => key.includes('__')).forEach(key => delete childNode[key])
  
          node.children.splice(newChildIndex, 0, childNode)
  
          successfulOps++
  
        } else {
  
          let childrenLength = node.children.length
  
          node.children = node.children.filter(child => child[uniqueKey] !== childUnique)
  
          if (childrenLength > node.children.length) {
            successfulOps++
          }
  
        }
  
        if (successfulOps < 2) { // we don't need to keep recursing once we've pruned AND added
          node.children.forEach( child => checkAndPruneOrAdd(child) )
        }
  
      }
  
      checkAndPruneOrAdd(this.state.tree)
      
      childNode = childNodes.pop()
    }

    this.updateFlatTree()
    
    const lastMovedChildIndex = newChildIndex
    const indexOfItemPrecedingFirstMovedChild = lastMovedChildIndex - movedCount
    const targetIndexForMove = indexOfItemPrecedingFirstMovedChild + 1

    return targetIndexForMove
  }

  // get the child entry for a tree node
  getElementForChild (node) {

    const uniqueKey = this.props.uniqueKey

    if (!node[uniqueKey]) { return null }
    
    // EARLY RETURN -- if we're editing this child, return a text input
    if (this.props.editingChild === node[uniqueKey]) {
      const handleKeyUp = (e) => {
        // in the case that the user hits the escape key, set the name to its
        // original value. userland code can elect to handle this case as a 
        // "cancel rename" event.
        if (e.key === "Escape") {
          this.props.handleRename(node[uniqueKey], node[this.labelKey])
        } else if (e.key === "Enter") {
          this.props.handleRename(node[uniqueKey], e.target.value)
        }
      }
      return <li
        className = "node input-node"
        key       = {node[uniqueKey]}
        >
          {icon}
          <input
            key          = {node[uniqueKey] + 'input'}
            type         = "text"
            placeholder  = {'please enter a name'}
            autoFocus
            defaultValue = {node[this.labelKey] || ""}
            onKeyUp      = {handleKeyUp}
            onBlur       = {(e) => {this.props.handleRename(node[uniqueKey], e.target.value)}}
          />
        </li>
    }

    const level = node.__level

    // programmatically indent this node depending on nesting
    // all other styles in stylesheet, please :)
    const styleObj = { paddingLeft : level * 20 }

    const classList = []

    classList.push('node')

    if (node.children && level !== 0) {
      classList.push('expandable')
    }

    if (
      node.__expanded
      && this.state.toBeHidden !== node[uniqueKey]
      && node.children
      && node.children.length
    ) {
      classList.push('expanded')
    }

    if (level <= 1) { classList.push('top-level') } else {
      classList.push('sub-level')
    }

    if (node[uniqueKey] === this.currentDropTargetIdentifier) {
      classList.push( `target drop-${this.currentDropLocation}`)
    }

    if (this.props.activeContextMenuNode === node[uniqueKey]) {
      classList.push('context-active')
    }

    if (this.props.nodesToHighlight && this.props.nodesToHighlight.includes(node[uniqueKey])) {
      classList.push('highlight')
    }
    if (this.justDragged.includes(node[uniqueKey])) {
      classList.push("just-dragged")
    }
    if (this.currentDragChildKeys.includes(node[uniqueKey])) {
      classList.push("active-drag-node")
    }
    if (this.slideInAfter == node[uniqueKey]) {
      classList.push("slide-in-after")
    }
    if  (this.slideInBefore == node[uniqueKey]) {
      classList.push("slide-in-before")
    }
    if (this.multiSelection.selected.has(node[uniqueKey])) {
      classList.push("selected")
    }
    
    const showContextMenu = this.state.contextMenuOpenFor == node[uniqueKey]
    const menuOptions = showContextMenu 
      ? this.props.contextMenuOptionsForNode(
          node, 
          this.closeContextMenu,
          node.children ? () => this.ensureExpanded(node[uniqueKey]) : null
        )
      : null

    const showContextMenuButton = node.hasContextMenu != false && (this.props.handleContextMenu || this.props.contextMenuOptionsForNode)
    const contextButton = showContextMenuButton
      ? <ContextMenuTrigger
          uniqueString={node[uniqueKey]} 
          menuOptions={menuOptions} 
          close={this.closeContextMenu} 
        />
      : null

    const expandButton = <button
          key         = {node[uniqueKey] + '-expand-button'}
          className   = "expand-button"
          data-unique = {node[uniqueKey]}
          data-type   = "expander"
        >
          <i>&rsaquo;</i>
        </button>

    let labelText = node[this.labelKey] || ""
    let label     = labelText // this will be overwritten if we match a searchTerm

    if (this.searchTerm && labelText.includes(this.searchTerm)) {

      label = []
      const labelFrags = labelText.split(this.searchTerm)
      labelFrags.forEach( (frag, index) => {
        label.push(frag)
        if (index + 1 < labelFrags.length) {
          label.push((<span key={node[uniqueKey] + '-frag-' + index} className="search-result-highlight">{this.searchTerm}</span>))
        }
       })
    }

    let icon = null
    if (node.iconClass) {
      // if there is an iconClass, stick an icon at the beginning of the label
      icon = (<i className={`__user-icon ${node.iconClass}`} key={`${node[uniqueKey]}-user-icon`}></i>)
      label = [icon].concat(<span key={`${node[uniqueKey]}-label`}>{label}</span>)
    }

    // if this is the root node, we can't drag it, otherwise, defer to whether or not the tree is locked
    const locked = node.__level === 0 ? true : this.isLocked()

    return <li
        draggable   = { locked ? false : true }
        data-unique = { node[uniqueKey] }
        style       = { styleObj }
        key         = { node[uniqueKey] }
        className   = { classList.join(' ') }
      >
        { node.children && node.children.length && level !== 0 ? expandButton : null }
        { node.href ? <a draggable={locked ? false : true} data-unique={ node[uniqueKey] } href={node.href}>{label}</a> : label }
        { contextButton }
      </li>

  }

  // from current drag state, infer drop output
  getChildParentTargetNodes () {

    // we potentially run this find twice.
    const dropTargetNode = this.flatTree.get(this.currentDropTargetIdentifier)
    const dragChildNodes = this.currentDragChildKeys.map(key => this.flatTree.get(key))

    if (!dropTargetNode || !dragChildNodes.length) { return [null, null, null]}

    let newParentId
    if (this.currentDropLocation === 'into' || dropTargetNode.__level === 0) {
      newParentId = this.currentDropTargetIdentifier
    } else {
      newParentId = dropTargetNode.__parent
    }

    // this *might* be a duplicate find if the parent and currentDropTarget are the same.
    const newParentNode = this.flatTree.get(newParentId)

    return [dragChildNodes, newParentNode, dropTargetNode]
  }
  
  isSelected (nodeKey) {
    return this.multiSelection.selected.has(nodeKey)
  }
  
  // add or remove from selection, e.g. cmd or ctrl click
  toggleSelectionMembership (nodeKey) {
    const node = this.flatTree.get(nodeKey)
    if (!node) return
    if (this.multiSelection.parent != node.__parent) {
      this.multiSelection.parent = node.__parent
      this.multiSelection.selected = new Set([nodeKey])
    } else {
      if (this.multiSelection.selected.has(nodeKey)) {
        this.multiSelection.selected.delete(nodeKey)
      } else {
        this.multiSelection.selected.add(nodeKey)
      }
    }
    this.forceUpdate()
  }
  
  // range selection, e.g. shift-click
  rangeSelectionTo (nodeKey) {
    const node = this.flatTree.get(nodeKey)
    if (node.__parent !== this.multiSelection.parent) {
      // if the node they've clicked is not a sibling of the current selection,
      // treat this as a normal selection action
      return this.toggleSelectionMembership(nodeKey)
    } else {
      const keys = [...this.flatTree.keys()]
      const nodeIndex = keys.indexOf(nodeKey)
      const currentSelectionIndexes = [...this.multiSelection.selected].map(key => keys.indexOf(key))
      const min = Math.min(...currentSelectionIndexes)
      const max = Math.max(...currentSelectionIndexes)
      
      let nodesToAdd = []
      if (nodeIndex < min) {
        nodesToAdd = keys.slice(nodeIndex, min)
      } else if (nodeIndex > max) {
        nodesToAdd = keys.slice(max, nodeIndex + 1)
      } else {
        // the user has clicked somewhere within the existing selection…
        // this is currently undefined behaviour, but could be implemented
        // as a removal from the selection? 
        // as a fill gaps in selection?
        // as a regular toggle? 
      }
      for (const nodeKey of nodesToAdd) {
        this.multiSelection.selected.add(nodeKey)
      }
      this.forceUpdate()
    }
    
  }
  
  getSelectionAsDraggable () {
    const keys = [...this.flatTree.keys()]
    return [...this.multiSelection.selected]
      .map(key => ({ index: keys.indexOf(key), key }))
      .sort((a,b) => a.index > b.index ? 1 : -1)
      .map(item => item.key)
  }
  
  /**
   * Set the selection to a specific node.
   */
  setSelectionToNode (nodeKey, skipRender = false) {
    const node = this.flatTree.get(nodeKey)
    this.multiSelection = {
      parent: node.__parent,
      selected: new Set([nodeKey])
    }
    if (!skipRender) this.forceUpdate()
  }
  
  clearSelection (skipRender = false) {
    if (this.multiSelection.selected.size == 0) return // selection already clear
    this.multiSelection = {
      parent: null,
      selected: new Set()
    }
    if (!skipRender) this.forceUpdate()
  }
  
  @autobind
  rootClearSelection (e) {
    const path = e.path || []

    // polyfill event.path for browsers that don't support it.
    if (!path.length) {
      let node = e.target
      while (node != document.body) {
        if (node == null) {
          // guard against a DOM Node being removed
          break
        }
        path.push(node)
        node = node.parentNode
      }
    }

    if (!path.includes(this.DOMNode) && this.multiSelection.selected.size > 0) {
      this.clearSelection()
    }
  }

  @autobind
  dragstart (e) {

    // target could be the `<li />` or the `<a />` if the user has initiated
    // drag by grabbing the text of a link
    const target = e.target

    // this will always be the `<li />` 
    const draggable = target.closest("li")

    // set some component attributes
    this.state.dragging = true

    // if the current node is part of a selection, pass the selection, otherwise,
    // just the current node.
    if (this.isSelected(draggable.dataset.unique)) {
      this.currentDragChildKeys = this.getSelectionAsDraggable()
    } else {
      this.currentDragChildKeys = [draggable.dataset.unique]
      this.clearSelection()
    }
    
    const dragImage = document.createElement("div")
    
    dragImage.classList.add("drag-status")
    
    const labels = []
    
    for (const childKey of this.currentDragChildKeys) {
      const node = this.flatTree.get(childKey)
      const label = node[this.labelKey]
      labels.push(label)
    }
    dragImage.innerHTML = labels.join(`<br />`)

    document.body.appendChild(dragImage)
    
    // set up the drag and drop session
    e.dataTransfer.dropEffect = "move"
    e.dataTransfer.setData('text/json', this.currentDragChildKeys);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
      
    // there is a known chrome & safari issue in which manipulating the DOM
    // directly from the dragstart handler can cause dragend to fire immediately
    // before the drag session gets going, so we introduce a brief timeout before
    // re-rendering the component.
    //
    // there's more info at this stack overflow thread:
    // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately?noredirect=1
    this.dragStartTimeout = setTimeout(() => {   
      document.body.removeChild(dragImage)  // once the preview image has picked up the pixel contents of this thing, we can scrap it
      this.updateFlatTree()
      this.forceUpdate()
    }, 10)

  }

  @autobind
  dragover (e) {

    // DANGER — this event fires A LOT, be careful with it.

    if (!e.target || !e.target.dataset.unique) {
      return e.preventDefault()
    }

    const target       = e.target
    const targetUnique = target.dataset.unique

    // grab a copy of the three class members this function can update
    const previous = {
      dropTarget: this.currentDropTargetIdentifier,
      dropLocation: this.currentDropLocation,
      dragAllowed: this.dragAllowed
    }

    this.currentDropTargetIdentifier = targetUnique

    // Compute drop position (before, after, or into)
    const dropTargetNode = this.flatTree.get(this.currentDropTargetIdentifier)
    // if the first dragged node can be contained, so can the rest of em -- multi-drag nodes
    // must always be siblings.
    const firstDragChildNode  = this.flatTree.get(this.currentDragChildKeys[0])
    const dropTargetCanContainDragged = this.canDragChildInto(firstDragChildNode, dropTargetNode);

    const mouseY     = e.clientY
    const clientRect = e.target.getBoundingClientRect()

    let dropLocation
    // if the node we're dragging over can contain the dragged item, we split
    // it into three regions: top = before, bottom = after, middle = into
    // otherwise it's split into two regions, before & after
    if (dropTargetCanContainDragged) {
      if (mouseY < (clientRect.top + (clientRect.height / 3)) ) {
        dropLocation = 'before'
      } else if (mouseY > (clientRect.bottom - (clientRect.height / 3)) ) {
        dropLocation = 'after'
      } else {
        dropLocation = 'into'
      }
    } else {
      if (mouseY < (clientRect.top + (clientRect.height / 2)) ) {
        dropLocation = 'before'
      } else {
        dropLocation = 'after'
      }
    }

    const isRoot = (dropTargetNode.__level === 0)
    this.currentDropLocation = dropLocation

    // guard against looking for parent of root
    const newParentNode = this.currentDropLocation === 'into' || isRoot
      ? dropTargetNode
      : this.flatTree.get(dropTargetNode.__parent)


    // can't think of a single case where dropping something into itself would work…
    const isSelf = (this.currentDragChildKeys.includes(newParentNode[this.props.uniqueKey]))


    const newParentCanContainDragged = this.canDragChildInto(firstDragChildNode, newParentNode);
    if (!isSelf && newParentCanContainDragged) {
      e.dataTransfer.dropEffect = 'move'
      this.dragAllowed = true
      e.preventDefault()
    } else {
      e.dataTransfer.dropEffect = 'none'
      this.dragAllowed = false
    }

    // if any of the three properties this function can change have changed,
    // force component update.
    if (
      previous.dropLocation !== this.currentDropLocation || 
      previous.dropTarget !== this.currentDropTargetIdentifier ||
      previous.dragAllowed !== this.dragAllowed) {
      this.dragoverUpdate()
    }

  }

  @autobind
  drop (e) {
    e.preventDefault()
    e.stopPropagation()

    const [dragChildNodes, newParentNode] = this.getChildParentTargetNodes()

    for (const dragChildNode of dragChildNodes) {
      if (!this.canDragChildInto(dragChildNode, newParentNode)) {
        console.error(`shouldn't be able to drop here`)
        return
      }
    }

    const oldParent = dragChildNodes[0].__parent

    const newIndex = this.pruneAndReattach(
      dragChildNodes, 
      newParentNode[this.props.uniqueKey], 
      this.currentDropLocation, 
      this.currentDropTargetIdentifier
    )
    
    const moveDefinition = {
      childId : this.props.multiDrag ? this.currentDragChildKeys : this.currentDragChildKeys[0],
      into    : newParentNode[this.props.uniqueKey],
      from    : oldParent,
      atIndex : (newIndex >= 0) ? newIndex : 0
    }

    if (this.props.childMoved) {
      this.props.childMoved(moveDefinition)
    } else {
      console.warn(`
        moved ${moveDefinition.childId}
        from ${moveDefinition.from},
        into ${moveDefinition.into}
        at index ${moveDefinition.atIndex},
        but you haven't passed in a 'childMoved' callback,
        so this event might not have any effect in your app
      `)
    }

    // if we've dropped into a closed target,
    if (!this.state.expandedItems.includes(this.currentDropTargetIdentifier) && this.currentDropLocation === 'into') {
      this.toggleExpanded(this.currentDropTargetIdentifier)
    }

  }

  @autobind
  dragend (e) {
    e.preventDefault()
    e.stopPropagation()

    this.justDragged = [...this.currentDragChildKeys]

    this.currentDragChildKeys = []
    this.currentDropTargetIdentifier = null
    this.currentDropLocation = null
    this.clearSelection(true)
    this.updateFlatTree()


    this.setState({
      dragging : false
    })

    if (this.justDraggedTimeout) clearTimeout(this.justDraggedTimeout)
    this.justDraggedTimeout = setTimeout(() => {
      this.justDragged = []
    }, 1010)

  }
  
  @autobind
  closeContextMenu () {
    this.setState({ contextMenuOpenFor: null })
  }
  

  @autobind
  handleClick (e) {
    if (e.target.nodeName === 'BUTTON' && e.target.dataset.type === 'context-menu-trigger') {
      const contextNodeId = e.target.dataset.unique
      if (this.props.contextMenuOptionsForNode) {
        this.setState({ contextMenuOpenFor: contextNodeId })
      } else {
        const contextNode   = this.flatTree.get(contextNodeId)
        const clientRect = e.target.getBoundingClientRect()
        this.props.handleContextMenu(contextNode, clientRect)
      }
    }

    if (e.target.nodeName === 'BUTTON' && e.target.dataset.type === 'expander') {
      this.toggleExpanded(e.target.dataset.unique)
    }

    if (e.target.nodeName === 'LI') {
      if (!this.props.multiDrag) return // we don't need to track a selection if multiDrag is switched off
      const nodeKey = e.target.dataset.unique
      // modifier keys. 
      const { altKey, metaKey, ctrlKey, shiftKey } = e
      if (ctrlKey || metaKey) {
        this.toggleSelectionMembership(nodeKey)
      } else if (shiftKey) {
        this.rangeSelectionTo(nodeKey)
      } else {
        if (this.multiSelection.selected.size > 0) this.clearSelection()
      }
    }

  }

  // render the whole thing
  render () {

    const contentTree = this.state.tree

    if (!contentTree) {
      console.warn('react large tree expected a \'content\' prop')
      return null
    }

    const flatContent = [...this.flatTree.values()]

    if (flatContent.length == 0) { return null }

    const elements  = flatContent
      .filter(node => node[this.props.uniqueKey])
      .map(child => this.getElementForChild(child))
      
    const classList = [
      `react-large-tree`,
      `dragging-${this.state.dragging}`,
      `drag-allowed-${this.dragAllowed}`, 
      `${this.justDragged.length ? "just-dragged" : ""}`,
      `${this.clickActionClass}`
    ]

    const list = elements && elements.length ? (
      <ol
        key={this.uniqueId + '-main-list'}
        className   = {classList.join(" ")}
        ref         = {ref => this.DOMNode = ReactDOM.findDOMNode(ref)}
        onDrop      = {this.drop}
        onDragOver  = {this.dragover}
        onDragStart = {this.dragstart}
        onDragEnd   = {this.dragend}
        onClick     = {this.handleClick}
      >
        {elements}
      </ol>
    ) : <span className="no-items" key={this.uniqueId + '-empty-results-message'}>No Items Present</span>

    return <div id={this.uniqueId} key={'react-large-tree-' + this.uniqueId}>
        <input
          className="tree-searcher"
          onKeyUp={(e) => this.doSearch(e.target.value) }
          placeholder={this.props.searchPlaceholder || 'Search 🔍'}
          key={this.uniqueId + '-search-input'}
        />
        {list}
    </div>
  }

}

export default ReactLargeTree;
