/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'
import { BAActionLink, BAButton, BATabButton, BAButtonsTab, BAFileInput } from "/lib/components/buttons.jsx"
import {useState} from 'react'

export const meta = {
  name: "Buttons",
  category: "Components",
  tags: [],
  components: ["BAActionLink", "BAButton", "BATabButton", "BAButtonsTab", "BAFileInput"],
  functions: [],
  values: {
    active: 3
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAButton & BAActionLink `}<br /><small>{`Covering 90% of our Buttony needs.`}</small></h1>
    <h2>{`BAButton`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<BAButton />`}</inlineCode>{` component provides an array of sizes, colours, and styles for the `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` element.`}</p>
    <h2>{`BAActionLink`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<BAActionLink />`}</inlineCode>{` component provides corresponding look and feel for the `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` element, and should be used for URL-based actions, for example, url-downloads, or login.`}</p>
    <p>{`Avoid using `}<inlineCode parentName="p">{`<BAActionLink />`}</inlineCode>{` for ordinary navigation anchors around and between application views.`}</p>
    <h2>{`Button Sizes`}</h2>
    <Example mdxType="Example">
  <BAButton size="micro" className="margin-right" mdxType="BAButton">Micro!</BAButton>
  <BAButton size="small" className="margin-right" mdxType="BAButton">Small!</BAButton>
  <BAButton size="medium" className="margin-right" mdxType="BAButton">Medium!</BAButton>
  <BAButton size="large" className="margin-right" mdxType="BAButton">Large!</BAButton>
  <BAButton size="jumbo" mdxType="BAButton">Jumbo!</BAButton>
    </Example>
    <p>{`Our sizes correspond to our typographic sizes.`}</p>
    <p>{`Small is by far the most common in our UI, though micro is also used where lots of functionality needs to be grouped into a small space, such as the header bar and the Report Editor.`}</p>
    <p>{`Medium sized buttons are best suited to major operations such as logging in.`}</p>
    <p>{`Large and Jumbo probably don't have use cases.`}</p>
    <h2>{`Button Colours`}</h2>
    <Example mdxType="Example">
  <div>
    <BAButton size="medium" className="margin-right" mdxType="BAButton">Default!</BAButton>
    <BAButton color={'highlight'} size="medium" className="margin-right" mdxType="BAButton">Highlight!</BAButton>
    <BAButton color={'confirm'} size="medium" className="margin-right" mdxType="BAButton">Confirm!</BAButton>
    <BAButton color={'warning'} size="medium" className="margin-right" mdxType="BAButton">Warning!</BAButton>
    <BAButton color={'hazard'} size="medium" className="margin-right" mdxType="BAButton">Hazard!</BAButton>
  </div>
  <div>
    <BAButton solid size="medium" className="margin-right margin-top" mdxType="BAButton">Default!</BAButton>
    <BAButton color={'highlight'} solid size="medium" className="margin-right margin-top" mdxType="BAButton">Highlight!</BAButton>
    <BAButton color={'confirm'} solid size="medium" className="margin-right margin-top" mdxType="BAButton">Confirm!</BAButton>
    <BAButton solid color={'warning'} size="medium" className="margin-right margin-top" mdxType="BAButton">Warning!</BAButton>
    <BAButton solid color={'hazard'} size="medium" className="margin-right margin-top" mdxType="BAButton"> Hazard!</BAButton>
  </div>
    </Example>
    <p>{`Use colour and solid colour to draw the eye sparingly and relevantly.`}</p>
    <p>{`For example, we use solid 'confirm' and 'hazard' in delete/confirm modals.`}</p>
    <p>{`But we avoid using the 'solid' variants in the 'Time Slice Commentary' window on dashboards because it would distract from the rest of the dashboard.`}</p>
    <h2>{`Button Styles`}</h2>
    <Example mdxType="Example">
  <BAButton rounded size="medium" className="margin-right" mdxType="BAButton">Rounded!</BAButton>
  <BAButton uppercase size="medium" className="margin-right" mdxType="BAButton">uppercase!</BAButton>
  <BAButton bare size="medium" className="margin-right" mdxType="BAButton">Bare!</BAButton>
  <BAActionLink solid color="confirm" size="medium" className="margin-top" mdxType="BAActionLink">Link styled as button</BAActionLink>
    </Example>
    <h2>{`With Icons`}</h2>
    <p>{`Use icons to add clarity to a button's meaning at a glance.`}</p>
    <p>{`Try to be consistent so that we are using the same icon to convey the same meaning everywhere - and if the button is part of a group, the whole group should either have icons or not.`}</p>
    <p>{`Icons are added to buttons as children.`}</p>
    <p>{`Use 'margin-left' or 'margin-right' in the icon's className to get correct positioning.`}</p>
    <Example mdxType="Example">
  <BAButton size="medium" className="margin-right" mdxType="BAButton"><i className="ba-icon ba-dashboard-icon margin-right" />Icon Left!</BAButton>
  <BAButton size="medium" className="margin" mdxType="BAButton">Icon Right!<i className="ba-icon ba-folder-icon margin-left" /></BAButton>
    </Example>
    <h2>{`BAButtonsTab & BATabButton`}</h2>
    <p>{`Tabbed UI using buttons`}</p>
    <h2>{`BAButtonsTab`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<BAButtonsTab />`}</inlineCode>{` component provides a flex wrapper for buttons to be displayed in a tabbed fashion. Thjese will need to be of the type `}<inlineCode parentName="p">{`<BATabButton>`}</inlineCode>{` which is an extension of `}<inlineCode parentName="p">{`<BAButton>`}</inlineCode>{`.`}</p>
    <h2>{`Grouped Buttons`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<BAButtonsTab />`}</inlineCode>{` component groups a series of `}<inlineCode parentName="p">{`<BATabButton />`}</inlineCode>{` controls together into a single UI element. Borders are collapsed between buttons. The row is laid out to size buttons evenly across available horizontal space.`}</p>
    <p><inlineCode parentName="p">{`<BATabButton />`}</inlineCode>{` accepts the same params as `}<inlineCode parentName="p">{`<BAButton />`}</inlineCode></p>
    <p>{`This pattern is useful for grouping related actions together, for example Save and Discard actions in a modal workflow.`}</p>
    <div>Value: {props.get("active")}</div>
    <Example mdxType="Example">
  <div style={{
        width: '300px'
      }}>
    <BAButtonsTab key='buttonsTab' mdxType="BAButtonsTab">
      <BATabButton id="1" onClick={() => props.set("active", props.get("active") + 2)} size="small" mdxType="BATabButton">+ 2</BATabButton>
      <BATabButton id="2" onClick={() => props.set("active", props.get("active") / 2)} size="small" mdxType="BATabButton">/ 2</BATabButton>
      <BATabButton id="3" onClick={() => props.set("active", props.get("active") * 3)} size="small" mdxType="BATabButton">* 3</BATabButton>
      <BATabButton id="4" onClick={() => props.set("active", props.get("active") - 1)} size="small" mdxType="BATabButton">- 1</BATabButton>
    </BAButtonsTab>
  </div>
    </Example>
    <h2>{`Circle buttons`}</h2>
    <p>{`You can make circular buttons by passing the `}<inlineCode parentName="p">{`circle`}</inlineCode>{` boolean prop like so:`}</p>
    <Example mdxType="Example">
  <BAButton size="micro" className="margin-right" circle mdxType="BAButton">M</BAButton>
  <BAButton size="small" className="margin-right" circle mdxType="BAButton">S</BAButton>
  <BAButton size="medium" className="margin-right" circle mdxType="BAButton">M</BAButton>
  <BAButton size="large" className="margin-right" circle mdxType="BAButton">L</BAButton>
  <BAButton size="jumbo" circle mdxType="BAButton">J</BAButton>
    </Example>
    <p>{`All of the regular sizes and colour properties will work just fine.`}</p>
    <p>{`Circle buttons are great with icons!`}</p>
    <Example twoColumn mdxType="Example">
  <BAButton size="large" className="margin-right" circle mdxType="BAButton">
    <i className="fas fa-trash-alt" />
  </BAButton>
    </Example>
    <h2>{`File Input 📁`}</h2>
    <p>{`A component that consists of a BAButton, a file input and a readout of the file that's been submitted.
Takes the same props as the BAButton for styling (size and colour).`}</p>
    <p>{`Also takes the following:`}</p>
    <ul>
      <li parentName="ul">{`disabled`}</li>
      <li parentName="ul">{`placeholder - for the file readout`}</li>
      <li parentName="ul">{`accept - file type accepted`}</li>
      <li parentName="ul">{`readout - boolean, render a span next to the button to show the chosen file.`}</li>
    </ul>
    <Example twoColumn mdxType="Example">
  <BAFileInput color="confirm" size="medium" accept=".csv" readout={true} mdxType="BAFileInput">
    Upload File
  </BAFileInput>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
