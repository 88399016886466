import React, {Component}    from 'react'
import styled, {css}         from 'styled-components'
import {BAPortal}            from './portal'
import {fadeIn}              from './_shared/animations'
import withRootClose         from './rootClose'

// MODAL COMPONENTS -----------------------------------------------------------

// a dark full screen bg
const ModalBG = styled.div`
animation: ${fadeIn} 0.2s linear;
background      : var(--dark-bg-trans);
position        : fixed;
z-index         : var(--z-index-layer-6);
margin          : auto;
width           : 100%;
height          : 100%;
align-items     : center;
justify-content : center;
overflow-y      : scroll;
.modal-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
  transform: translate3d(0,0,0);
}
`

// an individual modal container box for content.
const Modal = styled.div`

min-width     : 800px;
max-width     : 90vw;

animation     : ${fadeIn} 0.2s linear;

color         : var(--text-main);
background    : var(--content-bg);
border        : 3px solid var(--line);
border-radius : 0.5rem;

padding       : 1rem;
margin        : 2rem;
`

const closeButtonStyles = css`
cursor     : pointer;
font-size  : var(--text-size-medium);
outline    : none;
border     : none;
background : none;
color      : var(--text-main);
&:hover { color: var(--text-main-dark); }
`


const ModalHeader = styled.header`
border-bottom  : 2px solid var(--line);
padding        : 0.5rem 0;
display        : flex;
align-items    : center;
font-weight    : bold;
.close-button {
    position: relative;
    bottom: 0.8rem;
    left: 0.4em;
  ${closeButtonStyles}
}
`

ModalHeader.displayName = "ModalHeader"

const CloseButton = ({close}) => (
  <button
    onClick   = {close}
    className = "close-button margin-left-auto"
  >
    <i className="fas fa-times"/>
  </button>
)

CloseButton.displayName = "CloseButton"

const ModalBody = styled.aside`
padding : 1rem;
`

ModalBody.displayName = "ModalBody"

const ModalFooter = styled.footer`
padding-top     : 10px;
display         : flex;
${({customLayout}) => customLayout ? '' : 'justify-content : flex-end;'}
`
ModalFooter.displayName = "ModalFooter"

const RootClosingModal = withRootClose(Modal)

// A single, self-contained modal - to be added ad hoc to components which manage its 'open' state.
class BAModal extends Component {

  displayName = "BAModal"

  componentDidMount() {
    // close if escape key is pressed.
    if (this.props.noEsc) return
    document.addEventListener("keydown", this.handleEscPress.bind(this))
  }

  handleEscPress(e) {
    if (e.keyCode == 27) this.props.close()
  }

  componentWillUnmount() {
    if (this.props.noEsc) return
    document.removeEventListener("keydown", this.handleEscPress, false)
  }

  render() {

    const ActiveModal = this.props.noRootClose ? Modal : RootClosingModal

    return (
      <BAPortal isOpen={this.props.isOpen}>
        <ModalBG>
          <div className="modal-container">
            <ActiveModal {...this.props} onClose={this.props.close} role="dialog">
              {this.props.children}
            </ActiveModal>
          </div>
        </ModalBG>
      </BAPortal>
    )
  }
}

export {
  BAModal,
  ModalHeader,
  closeButtonStyles,
  CloseButton,
  ModalBody,
  ModalFooter
}
