/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'
import { BAColorPicker } from "/lib/components/colorpicker"

export const meta = {
  name: "Colour Picker",
  category: "Components",
  tags: ["colors", "colours"],
  components: ["BAColorPicker"],
  functions: [],
  values: {
    color1: '#f00'
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`ColorPicker`}</h1>
    <Example twoColumn mdxType="Example">
  <BAColorPicker color={props.get('color1')} label="Pick a colour!" labelLeft changeColor={color => {
        console.log('color', JSON.stringify(color));
        props.set('color1', color.hex);
      }} mdxType="BAColorPicker" />
    </Example>
    <p>{`N.B. we spell it 'color' in the code, and with the English spelling 'colour' if it's text to be displayed on-screen.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`BAColorPicker`}</inlineCode>{` component renders a square button which triggers a popover containing a colourpicker from the 'react-color' library.`}</p>
    <p>{`It takes the following props:`}</p>
    <ul>
      <li parentName="ul">{`color: the selected colour.`}</li>
      <li parentName="ul">{`colors: a pre-defined palette of colours to choose from.`}</li>
      <li parentName="ul">{`label: a string to appear next to the button.`}</li>
      <li parentName="ul">{`labelLeft: Boolean - should the label appear on the left or right?`}</li>
      <li parentName="ul">{`placement: "top", "bottom", "left" or "right" - direction the popper should render in`}</li>
      <li parentName="ul">{`changeColor: a change handling function.`}</li>
    </ul>
    <p>{`The React colorpicker outputs an object like the following, so you can decide what format you want to use in your change handler.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "hsl": {
    "h":0,
    "s":1,
    "l":0.5571428571428572,
    "a":1
  },
  "hex": "#ff1d1d",
  "rgb":{
    "r":255,
    "g":29,
    "b":29,
    "a":1
  },
  "hsv":{
    "h":0,
    "s":0.8857142857142857,
    "v":1,
    "a":1
  },
  "oldHue":0,
  "source":"rgb"
}
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
