import React            from 'react'
import {SketchPicker}   from 'react-color'
import {BAPopper}       from './tooltip'
import styled           from 'styled-components'
import {disabledStyles} from './_shared/vars'

// COLORPICKER
const ColorPicker = ({color, colors, changeColor}) => {

  const pickerProps = {
    width: 280,
    color,
    changeColor,
    onChange: changeColor
  }

  // Note: presetColors can also be an array of objects like [{ color: '#f00', title: 'red' }]
  if (colors && colors.length) pickerProps.presetColors = colors

  return <SketchPicker {...pickerProps} />
}

// size of the color picker square button
const getColorButtonSize = size => {
  switch (size) {
    case 'small': return `1.2rem`
    default: return `2rem`
  }
}

const ColorButton = styled.span`
  background: ${({bg}) => bg};
  ${disabledStyles}
  width: ${({size}) => getColorButtonSize(size)};
  height: ${({size}) => getColorButtonSize(size)};
  display: inline-block;
  &:hover {
    border: 1px solid black;
  }
`

// COLORPOPPER: A BUTTON THAT TRIGGERS A POPPER WITH A COLORPICKER
export const BAColorPicker = ({
  color,
  colors,
  changeColor,
  label,
  disabled,
  labelLeft,
  size,
  zIndex
}) => (
  <div className="flex-row align-center">
    {label && labelLeft ? (
      <span className="margin-half-right text-text-main text-small">
        {label}
      </span>
    ) : null}

    <BAPopper
      placement = "bottom"
      disabled  = {disabled}
      theme     = "light"
    zIndex    = {zIndex || 'var(--z-index-layer-6)'}
      content   = {
        <ColorPicker
          color       = {color}
          colors      = {colors}
          changeColor = {changeColor}
        />
      }
    >
      <ColorButton bg={color} size={size} />
    </BAPopper>

    {label && labelLeft ? null : (
      <label className="text-text-main text-small margin-half-left">
        {label}
      </label>
    )}
  </div>
)
